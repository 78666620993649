$(document).on('ready', function(){
    
    var header = $(".header");
    var headerTopPosition = 50;
    var buttonNav = $('.button_nav');
    var navigation = $('.navigation');
    var mobileLanguage = $('.mobile_language');
    var socialLanguage = $('.social_language-language');
    var windowWidth = $(window).width();

    
    buttonNav.on('click', function(){
        $(this).toggleClass('open');
        navigation.toggleClass('active');
        mobileLanguage.toggleClass('disabled');
    });

    mobileLanguage.on('click', function(){
        var isVisible = $('.disabled_language').css('visibility');
        if (isVisible === 'visible'){
            $('.disabled_language').css('visibility', 'hidden');
        }else{
            $('.disabled_language').css('visibility', 'visible');        
        }

    });

    $(window).scroll(function(){
        if ($(this).scrollTop() > headerTopPosition ) {
            navigation.addClass("fixed_inline").fadeIn();
            socialLanguage.addClass("fixed_language_right");
        }
        else{
            navigation.removeClass("fixed_inline");
            socialLanguage.addClass("fixed_language_right");
        }
    });

    var buttonManagementCountry = $('.button_management_country');

    buttonManagementCountry.on('click', function(event){
        event.preventDefault();
        buttonManagementCountry.removeClass('active');
        $(this).addClass('active');
        var managementCountry = $(this).attr('href');
        $('.management_navigation').removeClass('active');
        $('#' + managementCountry).addClass('active');
    });

    
    if( ($('.swipebox').length) ||  ($('.swipebox-video').length)){
        $( '.swipebox' ).swipebox({removeBarsOnMobile: false});
        $( '.swipebox-video' ).swipebox({removeBarsOnMobile: false});
    }


    var ajso = audiojs.createAll();

    audiojs.events.ready(function() {
        $('.audiojs .play-pause').click(function(){
            var thisIndex = $(this).parents('.audiojs').index('.audiojs');
            $.each(ajso, function(index,val){
                if ( index != thisIndex && ajso[index].playing ) ajso[index].pause();
            });
        });
    });

    if(('#schubert-dvd-slider').length){
        $("#schubert-dvd-slider").responsiveSlides({
          speed: 1000,
          timeout: 12000,          // Integer: Time between slide transitions, in milliseconds
          nav: true         
        });

        $('.rslides_nav.rslides1_nav.prev').html("<i class='icon-arrow_right'></i>");
        $('.rslides_nav.rslides1_nav.next').html("<i class='icon-arrow_right'></i>");               
    }

    if(('#dvd-schubert-accordion-activator').length){
        $('#dvd-schubert-accordion-activator').on('click', function() {
            $('#dvd-schubert-accordion-activator b').toggleClass('open');
            $('#dvd-schubert-accordion-text').slideToggle("slow", function() {
            // Animation complete.
            });           
        });
    }


});

$(window).on('load', function(){
    aload();        
});
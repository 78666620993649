$(document).on('ready', function(){

        var lang = $('html').attr('lang');
        var place = 'place_' + lang;

        console.log('lang = ' + lang);

        var translateDays = {
            "es" : {
                "1" : "lunes",
                "2" : "martes",
                "3" : "miercoles",
                "4" : "jueves",
                "5" : "viernes",
                "6" : "sábado",
                "0" : "domingo"
            },
            "ca" : {
                "1" : "dilluns",
                "2" : "dimarts",
                "3" : "dimecres",
                "4" : "dijous",
                "5" : "divendres",
                "6" : "dissabte",
                "0" : "diumenge"
            },
            "de" : {
                "1" : "montag",
                "2" : "dienstag",
                "3" : "mittwoch",
                "4" : "donnerstag",
                "5" : "freitag",
                "6" : "samstag",
                "0" : "sonntag"
            },
            "en" : {
                "1" : "monday",
                "2" : "tuesday",
                "3" : "wednesday",
                "4" : "thursday",
                "5" : "friday",
                "6" : "saturday",
                "0" : "sunday"
            },                                      
        };

        var translateMonths = {
            "es" : {
                "0" :  "e\nn\ne\nr\no",
                "1" :  "F\ne\nb\nr\ne\nr\no",
                "2" :  "M\na\nr\nz\no",
                "3" :  "A\nb\nr\ni\nl",
                "4" :  "M\na\ny\no",
                "5" :  "j\nu\nn\ni\no",
                "6" :  "j\nu\nl\ni\no",
                "7" :  "a\ng\no\ns\nt\no",
                "8" :  "s\ne\np\nt\ni\ne\nm\nb\nr\ne",  
                "9" :  "o\nc\nt\nu\nb\nr\ne",  
                "10" :  "n\no\nv\ni\ne\nm\nb\nr\ne",  
                "11" :  "d\ni\nc\ni\ne\nm\nb\nr\ne" 
            },
            "ca" : {
                "0" :  "g\ne\nn\ne\nr",
                "1" :  "F\ne\nb\nr\ne\nr",
                "2" :  "M\na\nr\nç",
                "3" :  "A\nb\nr\ni\nl",
                "4" :  "M\na\ni\ng",
                "5" :  "j\nu\nn\ny",
                "6" :  "j\nu\nl\ni\no\nl",
                "7" :  "a\ng\no\ns\nt",
                "8" :  "s\ne\nt\ne\nm\nb\nr\ne",  
                "9" :  "o\nc\nt\nu\nb\nr\ne",  
                "10" :  "n\no\nv\ne\nm\nb\nr\ne",  
                "11" :  "d\ne\ns\ne\nm\nb\nr\ne"  
            },
            "de" : {
                "0" :  "j\na\nn\nu\na\nr",
                "1" :  "f\ne\nb\nr\nu\na\nr",
                "2" :  "m\nä\nr\nz",
                "3" :  "a\np\nr\ni\nl",
                "4" :  "m\na\ni",
                "5" :  "j\nu\nn\ni",
                "6" :  "j\nu\nl\ni",
                "7" :  "a\nu\ng\nu\ns\nt",
                "8" :  "s\ne\np\nt\ne\nm\nb\ne\nr",  
                "9" :  "o\nk\nt\no\nb\ne\nr",  
                "10" :  "n\no\nv\ne\nm\nb\ne\nr",  
                "11" :  "d\ne\nz\ne\nm\nb\ne\nr"  
            },
            "en" : {
                "0" :  "j\na\nn\nu\na\nr\ny",
                "1" :  "f\ne\nb\nr\nu\na\nr\ny",
                "2" :  "m\na\nr\nc\nh",
                "3" :  "a\np\nr\ni\nl",
                "4" :  "m\na\ny",
                "5" :  "j\nu\nn\ne",
                "6" :  "j\nu\nl\ny",
                "7" :  "a\nu\ng\nu\ns\nt",
                "8" :  "s\ne\np\nt\ne\nm\nb\ne\nr",  
                "9" :  "o\nc\nt\no\nb\ne\nr",  
                "10" :  "n\no\nv\ne\nm\nb\ne\nr",  
                "11" :  "d\ne\nc\ne\nm\nb\ne\nr", 
            },                                      
        };


    function loadTemplate(data, i){
        
        cutDayMonthYear = data[i].date.split(/ /);
        dateParts = cutDayMonthYear[0].split(/-/);

        var year = dateParts[0];
        var month = dateParts[1];
        var day = dateParts[2];

        //var convertDate = new Date(year, month, day);
        //https://stackoverflow.com/questions/4310953/invalid-date-in-safari
        var convertDate = new Date(data[i].date.replace(/-/g, "/"));

        var numberOfTheMonth = convertDate.getMonth();
        var concertColor;

        //console.log("day " + day + 'month ' + month + " getday " + convertDate.getDay() + ' getmonth ' + convertDate.getMonth());


        switch (numberOfTheMonth) {
            case 1:
            case 5:
            case 9:
                concertColor = "concert_color2";
                break;
            case 2:
            case 6:
            case 10:
                concertColor = "concert_color3";
                break;
            case 3:
            case 7:
            case 11:
                concertColor = "concert_color4";
                break;
            default:
                concertColor = "concert_color1";
        }


        var template = '';
        template += '<section class="concerts-item ' + concertColor + ' concerts_onload">';
        template += '<div class="concerts-item-patterns"><div class="concerts-item-patterns-top"></div></div>';
        template += '<div class="concerts-item-description">';
        template += '<p class="concerts-item-description-city h3 weight_light uppercase">'  + data[i][place] +  '</p>';
        template += '<p class="concerts-item-description-place uppercase weight_bold">' + data[i].location + ' / ' + translateDays[lang][convertDate.getDay()]  + ' ' + data[i].hour +' H.</p>';
        template += '<div class="concerts-item-description-repertoire">' + data[i].repertoire +'</div>';
        template += '</div>';
        template += '<p class="concerts-item-data vertical_text weight_bold">';
        template += '<span class="concerts-item-data-day">\n' +  day + '\n</span>\n'+ translateMonths[lang][convertDate.getMonth()] +' <br>\n<br>';
        template += '<strong>' + year + '</strong>';
        template += '</p>';
        template += '</section';

        return template;
    }


    $('#filter_by_year').on('change', function(event){
        event.preventDefault();
        var year = $('#filter_by_year').val();
        $('.concert-main-title').text(year);
        $('.subnavigation a').removeClass('active');

        
        //var loading_image_path = $('.concerts-filter_by_year').attr('loading-image-src');
        //$('.concerts_list').html('<p class="concerts_loading vertical_space_top"><img src="' + loading_image_path + '" /></p>');
        
        $('.concerts_list').html("<div class='loader'>Loading...</div>");      
        $('.pagination').css('display', 'none');

        $.get( "json-concerts/" + year)
            .done( function( data ) {
                for (var i = 0; i < data.length; i++){
                    $('.concerts_list').append( loadTemplate( data, i ) );
                }

                window.setTimeout(function(){
                    $('.concerts-item').removeClass('concerts_onload');
                    $('.loader').css('display', 'none');

                }, 1000);
                
            })
            .error(console.log("....."));
    });


});